<template>
    <div>
        <!--AxSysPartneruserList.vue
        {
            pageFlag: true,
            id: "ax_sys_partneruser",
            title: "用户登录表",
            name: "ax_sys_partneruser",
            icon: "el-icon-document",
            component: "views/chanjet/AxSysPartneruserList.vue",
            componentdetail: "views/chanjet/AxSysPartneruserDetail.vue",
            visible: true
        }
        -->
        <el-container>
            <el-main>
                <el-button-group>
                    <el-button type="" size="mini" @click="syslistquery">查询</el-button>
                    <el-button type="" size="mini" @click="syslistadd">新增</el-button>
                </el-button-group>
                <vxe-form size="mini">
                    <vxe-form-item title="编码" field="name" :item-render="{}" style="width:60px;">
                        <vxe-input v-model="p.userid" size="mini" placeholder="编码"></vxe-input>
                    </vxe-form-item>
                </vxe-form>
                <vxe-table :cell-style="rowStyle" show-overflow highlight-current-row border show-header-overflow highlight-hover-row size="mini" :data="rs" :height="meta.h">
                    <vxe-table-column field="guid" title="guid" :visible ="false"  width="180"></vxe-table-column>
                    <vxe-table-column field="userid" title="登录号" width="250"></vxe-table-column>
                    <vxe-table-column field="password" title="密码" width="250"></vxe-table-column>
                    <vxe-table-column field="partnercode" title="对应往来编码" width="250"></vxe-table-column>
                    <vxe-table-column field="remark" title="备注" width="1,000"></vxe-table-column>
                    <vxe-table-column fixed="right" width="120" title="操作">
                        <template #default="{row, rowIndex }">
                            <a @click="syslistdeleteone(row)">删除 | </a>
                            <a @click="syslistqueryone(row)">详细</a>
                        </template>
                    </vxe-table-column>
                </vxe-table>
                <vxe-pager :align="'left'" background :current-page.sync="p1.page.current" :page-size.sync="p1.page.size" :total="p1.page.total" @page-change="pagechange" :page-sizes=[100,500,1000,10000] :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">
                </vxe-pager>
            </el-main>
        </el-container>
    </div>
</template>
<script>
    import ax from "@/js/ax.js";
    import Vue from "vue";
    export default {
        name: "ax_sys_partneruserlist",
        extends: ax.control1(),
        data() {
            return {
                p: { name: "" },
                model: "chanjet",
                settings: "axSysPartneruser",
                vouchertype: "list",
            }
        },
    }
</script>